//3d model
window.onload = function() {
  // Mostrar la clase "svg" con opacidad 1 después de cargar completamente
  setTimeout(function() {
    const svgElement = document.querySelector('.svg');
    svgElement.style.opacity = '1';
  }, 1000); // 1000 milisegundos = 1 segundo
};


